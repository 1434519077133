@use 'assets/styles/utils/helper' as h;

.container,
.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.container ~ :global(.fluidWidth) {
  overflow: hidden;
}

.backdrop {
  background-color: rgba(0, 0, 0, 0.4);
}

.banner {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 20;
}

.gate {
  height: auto;
  padding: h.rem(30px 0 25px);
  background-color: var(--accent-orange-light);
  margin: 0;
}
